// Global styles for the dashboard and landing page

export const COLORS = {
  fontColor1: '#ffffff',
  fontColor2: '#cccccc',
  actionColor1: '#15E7BC',
  actionColor2: '#FF46B5',
  actionColor3: '#56CCF2',
  actionColor4: '#FF5959',
  grayColor1: '#EDEDEF',
  grayColor2: '#D2D3D6',
  grayColor3: '#B1B3B8',
  grayColor4: '#696C76',
  grayColor5: '#2C3436',
  grayColor6: '#242A2B',
  grayColor7: '#151E1F',
  grayColor8: '#071215'
}

export const SIZE = {
  xs: '0.5rem',
  sm: '1rem',
  md: '2rem',
  l: '2.5rem',
  xl: '3rem',
  xxl: '3.5rem'
}
