export const testData = [
  {
    name: 'topic 1',
    description: 'asd'
  },
  {
    name: 'topic 2',
    description: 'asd'
  },
  {
    name: 'topic 3',
    description: 'asd'
  },
  {
    name: 'topic 4',
    description: 'asd'
  },
  {
    name: 'topic 5',
    description: 'asd'
  },
  {
    name: 'topic 6',
    description: 'asd'
  },
  {
    name: 'topic 7',
    description: 'asd'
  }
]
