// amplify
import { API, graphqlOperation } from 'aws-amplify'
// queries
import { getTopicByIdQuery, listTopicsQuery } from '../../dashboard/graphql/queries'
// constants
import TOPICS from '../constants/topics.constants'

const { TOPICS_LIST, GET_ALL_TOPICS, LOADING, ERROR, CLEAR_TOPICS } = TOPICS

export const setTopicsAction = data => {
  return {
    type: TOPICS_LIST,
    payload: data
  }
}

export const getAllTopicsAction = userTopicId => async dispatch => {
  dispatch({
    type: LOADING
  })

  try {
    let r = null
    if (userTopicId !== null && typeof userTopicId !== 'undefined') {
      r = await API.graphql(graphqlOperation(getTopicByIdQuery(userTopicId)))
    }

    const response = await API.graphql(graphqlOperation(listTopicsQuery()))

    if (r?.data?.getTopic) {
      response.data.listTopics.items.push(r.data.getTopic)
    }

    dispatch({
      type: GET_ALL_TOPICS,
      payload: response.data.listTopics.items
    })
  } catch (error) {
    console.log('err:', error)
    dispatch({
      type: ERROR,
      payload: 'Error in get topics'
    })
  }
}

export function onClearTopics () {
  return (dispatch) => {
    dispatch(clear())
  }
}

const clear = () => ({
  type: CLEAR_TOPICS,
  payload: []
})
