// import { TODAY } from './globals'

export const MenuDateHeaderComponent = [
  // {
  //   index: 0,
  //   name: 'lastDay',
  //   value: TODAY
  // },
  {
    index: 0,
    name: 'last24',
    value: ''
  },
  {
    index: 1,
    name: 'last3Day',
    value: ''
  },
  {
    index: 2,
    name: 'lastWeek',
    value: ''
  },
  {
    index: 4,
    name: 'lastMonth',
    value: ''
  },
  {
    index: 5,
    name: 'lastYear',
    value: ''
  }
  // {
  //   index: 6,
  //   name: 'custom',
  //   value: ''
  // }
]

export const MenuFilterStateAffirmationsListComponentDefaultValueState = {
  index: -1,
  name: 'state',
  value: 'state'
}

export const MenuFilterStateAffirmationsListComponent = [
  {
    index: 0,
    name: 'all',
    value: 'all'
  },
  {
    index: 1,
    name: 'flowing',
    value: 'flowing'
  },
  {
    index: 2,
    name: 'blocked',
    value: 'blocked'
  },
  {
    index: 3,
    name: 'incomplete',
    value: 'incomplete'
  }
]

export const MenuFilterTopicsAffirmationsListComponent = [
  {
    index: 'T0',
    name: 'spirit',
    value: 'spirit'
  },
  {
    index: 'T1',
    name: 'health',
    value: 'health'
  },
  {
    index: 'T2',
    name: 'finance',
    value: 'finance'
  },
  {
    index: 'T3',
    name: 'love',
    value: 'love'
  },
  {
    index: 'T4',
    name: 'family',
    value: 'family'
  },
  {
    index: 'T5',
    name: 'parenting',
    value: 'parenting'
  },
  {
    index: 'T6',
    name: 'personal',
    value: 'personal'
  },
  {
    index: 'T7',
    name: 'performance',
    value: 'performance'
  }
]

export const MenuItemNewAffirmationComponent = [
  {
    index: 0,
    name: 'editAffirmation',
    value: 'edit'
  }
  // {
  //   index: 1,
  //   name: 'deleteAffirmation',
  //   value: 'delete'
  // }
]
