const Topics = {
  spirit: 'spirit',
  health: 'health',
  finance: 'finance',
  fun: 'fun',
  love: 'love',
  family: 'family',
  parenting: 'parenting',
  personal: 'personal',
  perfomance: 'perfomance'
}

export default Topics
