import logo from '../assets/img/logo_sensie_white.png'
import topic from '../assets/icons/topics/Topics.png'
import spirit from '../assets/icons/topics/spirit.svg'
import health from '../assets/icons/topics/health.svg'
import finance from '../assets/icons/topics/finance.svg'
import fun from '../assets/icons/topics/fun.svg'
import love from '../assets/icons/topics/love.svg'
import family from '../assets/icons/topics/family.svg'
import parenting from '../assets/icons/topics/parenting.svg'
import personal from '../assets/icons/topics/personal.svg'
import performance from '../assets/icons/topics/performance.svg'
import avatarFemale from '../assets/img/avatar_female.jpg'
import avatarMale from '../assets/img/avatar_male.jpg'
import noImg from '../assets/img/not-found-img.png'
import connectionMomentsImg from '../assets/img/Connection-moments.jpg'
import joyAffirmationsImg from '../assets/img/Joy-affirmations.jpg'
import sleepImg from '../assets/img/sleep.jpg'
import spiritImg from '../assets/img/spirit.JPG'
import healthImg from '../assets/img/health.JPG'
import financeImg from '../assets/img/finance.JPG'
import funImg from '../assets/img/fun.JPG'
import loveImg from '../assets/img/love.JPG'
import familyImg from '../assets/img/family.JPG'
import parentingImg from '../assets/img/parenting.JPG'
import personalImg from '../assets/img/perseonal.JPG'
import performanceImg from '../assets/img/performance.JPG'

const IMG = {
  logo,
  avatarFemale,
  avatarMale,
  noImg,
  connectionMomentsImg,
  joyAffirmationsImg,
  sleepImg,
  spiritImg,
  healthImg,
  financeImg,
  funImg,
  loveImg,
  familyImg,
  parentingImg,
  personalImg,
  performanceImg,
  icon: [
    {
      name: 'topic',
      src: topic
    },
    {
      name: 'spirit',
      src: spirit
    },
    {
      name: 'health',
      src: health
    },
    {
      name: 'finance',
      src: finance
    },
    {
      name: 'fun',
      src: fun
    },
    {
      name: 'love',
      src: love
    },
    {
      name: 'family',
      src: family
    },
    {
      name: 'parenting',
      src: parenting
    },
    {
      name: 'personal',
      src: personal
    },
    {
      name: 'performance',
      src: performance
    }
  ]
}

export default IMG
