/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from 'react'
import { Box, makeStyles, Grid } from '@material-ui/core'

const useStyles = makeStyles({
  boxStyle: {
    fontSize: '4.3rem',
    color: 'white',
    textAlign: '-webkit-center',
    fontWeight: 'bold'
  }
})

const WebAndMobileTitle = ({
  title,
  textWithColor,
  textWithoutColor,
  textWithoutColor2
}) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={0} sm={1}></Grid>
      <Grid item xs={12} sm={10}>
      <Box mx={4} mt={1} mb={10} className={classes.boxStyle} alignSelf="center">
        {textWithoutColor}
        <span className={classes.boxStyle} style={{ color: '#15E7BC' }}> {textWithColor} </span>{' '}
        {textWithoutColor2}{' '}
      </Box>
      </Grid>
      <Grid item xs={0} sm={1}></Grid>
    </Grid>
  )
}

export default WebAndMobileTitle
