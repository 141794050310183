const FILTERS = {
  GLOBAL_DATE_FILTER: 'GLOBAL_DATE_FILTER',
  AFFIRMATIONS_STATE_FILTER: 'AFFIRMATIONS_STATE_FILTER',
  AFFIRMATIONS_TOPIC_FILTER: 'AFFIRMATIONS_TOPIC_FILTER',
  AFFIRMATIONS_PACK_FILTER: 'AFFIRMATIONS_PACK_FILTER',
  AFFIRMATIONS_AFFIRMATION: 'AFFIRMATIONS_AFFIRMATION',
  CLEAR_FILTERS: 'CLEAR_FILTERS'
}

export default FILTERS
