const PACKS = {
  PACKS_LIST: 'PACKS_LIST',
  GET_ALL_PACKS: 'GET_ALL_PACKS',
  CREATE_PACK: 'CREATE_PACK',
  UPDATE_PACK: 'UPDATE_PACK',
  DELETE_PACK: 'DELETE_PACK',
  LOADING: '[PACKS] LOADING',
  ERROR: '[PACKS] ERROR',
  CLEAN_NEWPACK: 'CLEAN_NEWPACK',
  CLEAR_PACKS: 'CLEAR_PACKS'
}

export default PACKS
