const moment = require('moment')

export const TODAY = moment().utc().format()

export const UserListBtns = {
  summary: 'summary',
  details: 'details'
}

export const CreatePacksTags = {
  topics: 'topics',
  packs: 'packs'
}
