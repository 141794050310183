const AFFIRMATIONS = {
  NEW_AFFIRMATION: 'NEW_AFFIRMATION',
  EDIT_AFFIRMATION: 'EDIT_AFFIRMATION',
  LAST_AFFIRMATIONS: 'LAST_AFFIRMATIONS',
  CLEAN_NEW_AFFIRMATION: 'CLEAN_NEW_AFFIRMATION',
  CLEAN_EDIT_AFFIRMATION: 'CLEAN_EDIT_AFFIRMATION',
  CLEAN_LAST_AFFIRMATIONS: 'CLEAN_LAST_AFFIRMATIONS',
  GET_ALL_AFFIRMATIONS: 'GET_ALL_AFFIRMATIONS',
  CREATE_AFFIRMATION: 'CREATE_AFFIRMATION',
  DELETE_AFFIRMATION: 'DELETE_AFFIRMATION',
  LOADING: '[AFFIRMATIONS] LOADING',
  ERROR: '[AFFIRMATIONS] ERROR',
  CLEAR_AFFIRMATION: 'CLEAR_AFFIRMATION'
}

export default AFFIRMATIONS
