const INVITATIONS = {
  CREATE_INVITATION: 'CREATE_INVITATION',
  MODIFY_PACK_INVITATION: 'MODIFY_PACK_INVITATION',
  GET_INVITATION: 'GET_INVITATION',
  GET_LAST_INVITATION: 'GET_LAST_INVITATION',
  UPDATE_INVITATIONS_LOCAL: 'UPDATE_INVITATIONS_LOCAL',
  LOADING_INVITATIONS: '[INVITATIONS] LOADING',
  ERROR_INVITATION: '[INVITATIONS] ERROR',
  CLEAR_INVITATIONS: 'CLEAR_INVITATIONS'
}

export default INVITATIONS
