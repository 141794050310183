// react
import React, { Fragment, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
// components
import AffirmationChart from '../../components/AffirmationChart'
import MenuListComposition from '../../components/MenuListComposition'
import MultipleSelectCheckbox from '../MultipleSelectCheckbox'
import Icon from '../../components/Icon'
import Chip from '../../components/Chip'
import Title from '../../components/Title'
// import Pagination from '../../components/Pagination'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { setAffirmationsStateFilterAction, setAffirmationsTopicFilterAction, setAffirmationsPackFilterAction, setAffirmationAction } from '../../../redux/actions/filters.actions'
// import { listUsersByOrganizationIdAction } from '../../../redux/actions/users.actions'
// import { listAffirmationsByCoachId } from '../../../redux/actions/affirmations.actions'
// import { setPaginationAffirmationsListAction } from '../../../redux/actions/pagination.actions'
// constants
import { MenuFilterStateAffirmationsListComponent } from '../../constants/menus'
import { COLORS } from '../../constants/theme'
// import { listPacksAction } from '../../../redux/actions/packs.actions'
// utils
import { handleFlow } from '../../utils/functions'
// styles
import styles from './styles.module.scss'
// test data
// import { data } from './data'

// const
const { fontColor1, grayColor5 } = COLORS

// * container
/**
 * AffirmationsList container
 * @component
 * @param {boolean} chipsUp (default: false)
 * @param {number} limit
 * @param {string} title (default: ')
 * @param {number} theme (1, 2, 3) -> 1: default; 2: change title; 3: change backgroundColor & padding
 * @param {undefined} getSensies (default: () => {})
 */
const AffirmationsList = ({ chipsUp = false, multiUser = true, user, limit, title = '', theme = 1, getSensies = () => {} }) => {
  // ? hooks
  const dispatch = useDispatch()
  const {
    affirmationsReducer,
    usersReducer,
    filtersReducer: { affirmations: { packFilter, topicFilter, stateFilter, affirmation } }
    // paginationReducer: { pagination: { pagAffirmationsList } }
  } = useSelector(state => state)
  const [t] = useTranslation('global')
  const [selectValue, setSelectValue] = useState(topicFilter)

  const [selectPackValue, setSelectPackValue] = useState(packFilter)

  // useEffect(() => {
  //   const filter = user ? user.subscribedPacks.items.map(p => p.pack.id) : null
  //   dispatch(listPacksAction(user.id, filter))
  // }, [user])

  useEffect(() => {
    selectValue !== topicFilter && dispatch(setAffirmationsTopicFilterAction(selectValue))
    selectPackValue !== packFilter && dispatch(setAffirmationsPackFilterAction(selectPackValue))
  }, [selectValue, selectPackValue])

  // ? handle Functions
  /**
   * handle click state menu
   * @param {DataAffirmation} value
   * @returns {undefined} setAffirmationsStateFilterAction (dispatch REDUX)
   */
  const handleClickStateMenu = value => dispatch(setAffirmationsStateFilterAction(value))

  /**
   * handleTotalAffirmations
   * @returns {number} total
   */
  const handleTotalAffirmations = () => affirmationsReducer.affirmations.length

  /**
   * handle click topic menu
   * @param {DataAffirmation} value
   * @returns {undefined} selectValue = value
   */
  const handleClickTopicMenu = value => setSelectValue(value)

  const handleClickPackMenu = value => setSelectPackValue(value)

  /**
   * handle click close chip
   * @param {DataAffirmation} value
   * @returns {undefined} selectValue = value(filtered)
   */
  const handleClickCloseChip = value => setSelectValue(selectValue.filter(item => item !== value))

  /**
   * handle click affirmation
   * @param {AffirmationChart} value
   * @returns {AffirmationChart} redux
   */
  const handleClickAffirmation = value => dispatch(setAffirmationAction(value))

  /**
   * handle theme styles (theme = 3)
   * @returns {string} return style object
   */
  const handleTheme3Styles = () => {
    const styles = {}
    if (theme === 3) {
      styles.backgroundColor = 'transparent'
      styles.padding = '10px 0px'
    } else {
      styles.backgroundColor = grayColor5
      styles.padding = '10px 20px'
    }
    return styles
  }

  /**
   * handle paginaion change
   * @param {*} event
   * @param {number} value
   * @returns {undefined} redux action
   */
  // const handlePaginationChange = (event, value) => {
  //   dispatch(setPaginationAffirmationsListAction(value))
  // }

  // ? render functions
  /**
   * render multiple select - checkbox (children)
   * @return {undefined} (html)
   */
  const renderMultipleSelectCheckboxChildren = () => {
    return (
      <Fragment>
        {
          selectValue.length === 0
            ? <Icon custom="topic" color={fontColor1} size="md" />
            : <span className={styles.AffirmationsListMultipleSelectCheckboxItemCount}>
                {selectValue.length}
              </span>
        }
        <span>{t('dashboard.MultipleSelectCheckbox.topics')}</span>
        <Icon name="arrow-ios-downward-outline" color={fontColor1} size="md" />
      </Fragment>
    )
  }

  const renderMultipleSelectCheckboxPackChildren = () => {
    return (
      <Fragment>
        {
          selectPackValue.length === 0
            ? <Icon custom="topic" color={fontColor1} size="md" />
            : <span className={styles.AffirmationsListMultipleSelectCheckboxItemCount}>
                {selectPackValue.length}
              </span>
        }
        <span>{t('dashboard.MultipleSelectCheckbox.packs')}</span>
        <Icon name="arrow-ios-downward-outline" color={fontColor1} size="md" />
      </Fragment>
    )
  }
  /**
   * render Icon
   * @return {undefined} Icon (html)
   */
  const renderMenuListCompositionChildren = () => (
    <Icon name="activity-outline" color={fontColor1} size="md" />
  )

  /**
   * render chips items
   * @return {undefined} Chips[] (html)
   */
  const renderChipsItems = () => {
    return selectValue.map(item => <Chip key={item.index} label={item} onClose={value => handleClickCloseChip(value)}/>)
  }

  /**
   * renderAffirmationChart
   * @param {object} data
   * @param {number} flow
   */
  const renderAffirmationChart = (data, flow) => <AffirmationChart key={data.id} data={data} value={flow} onClickValue={value => handleClickAffirmation(value)} isActive={affirmation === data} multiUser={multiUser} />

  /**
   * render affirmations
   * @return {undefined} AffirmationChart[] (html)
   */
  const renderAffirmationsAffirmationChart = () => {
    let _data = affirmationsReducer.affirmations
    const topicIds = selectValue.map(v => v.id)
    const userPacks = user ? user.subscribedPacks.items.map(p => p.pack ? p.pack.id : false) : []
    const packIds = selectPackValue.map(v => v.id)

    if (userPacks.length > 0) {
      _data = _data.filter(item => {
        const packMatches = item.packs.items.filter(t => {
          return t.pack ? userPacks.indexOf(t.pack.id) > -1 : false
        })
        return packMatches.length > 0
      })
    }
    if (packIds.length > 0) {
      _data = _data.filter(item => {
        const packMatches = item.packs.items.filter(t => {
          return t.pack ? packIds.indexOf(t.pack.id) > -1 : false
        })
        return packMatches.length > 0
      })
    }
    if (topicIds.length > 0) {
      _data = _data.filter(item => {
        const topicMatches = item.topics.items.filter(t => {
          return t.topic ? topicIds.indexOf(t.topic.id) > -1 : false
        })
        return topicMatches.length > 0
      })
    }
    const clientIds = multiUser ? usersReducer.users.map(client => client.id) : [user.id]
    // const clientIds = usersReducer.users.map(client => client.id)
    // const _ids = _data.map(i => i.id)
    // _data = _data.filter((v, i, s) => { return _ids.indexOf(v.id) === i })
    _data = _data.map(item => {
      // console.log('aff: ', item)
      const users = new Set()
      const filtered = item.sensies.items.filter(s => clientIds.indexOf(s.userId) > -1)
      filtered.forEach(e => users.add(e.userId))
      item.sensies.items = filtered
      return Object.assign(item, {
        _flow: handleFlow(item.sensies.items),
        _userCount: users.size
      })
    })

    _data.sort((a, b) => {
      if (a._flow === 'NO_SENSIES') return 1
      return (parseInt(b._flow) === parseInt(a._flow)) ? (b._userCount - a._userCount) : (parseInt(b._flow) > parseInt(a._flow) ? 1 : -1)
    })
    // _data.sort((a, b) => {
    //   return (b._userCount === a._userCount) ? (b.sensies.items.length - a.sensies.items.length) : (b._userCount - a._userCount)
    // })
    // _data.sort((a, b) => a._packId === b._packId ? 1 : -1)
    if (_data.length < 1) return <span>{t('dashboard.IntentionsList.noData')}</span>
    return !affirmationsReducer.loading && _data.map(item => {
      // const flow = handleFlow(item.sensies.items)
      // console.log('flow: ', flow)
      // console.log(stateFilter.value, item._flow, typeof item._flow, item._flow === 0)
      switch (stateFilter.value) {
        case 'flowing': return item._flow >= 50 && renderAffirmationChart(item, item._flow)
        case 'blocked': return item._flow < 50 && renderAffirmationChart(item, item._flow)
        case 'all': return renderAffirmationChart(item, item._flow)
        case 'incomplete': return item._flow === 'NO_SENSIES' && renderAffirmationChart(item, item._flow)
        default: return renderAffirmationChart(item, item._flow)
      }
    })
  }

  return (
    <section className={styles.AffirmationsListContainer}>
      <div className={styles.AffirmationsListFiltersContainer}>
        {theme === 2 && <div className={styles.AffirmationsListFilterBtnMenuTitleContainer}>
          <Title text={title} />
        </div>}
        <div className={theme === 2 ? styles.AffirmationsListFilterBtnMenuContainerTheme2 : styles.AffirmationsListFilterBtnMenuContainerTheme1}>
          <div className={styles.AffirmationsListFilterBtnMenu}>
            <div className={styles.AffirmationsListFilterBtnMenuComponent}>
              <MenuListComposition
                data={MenuFilterStateAffirmationsListComponent}
                onClickValue={value => handleClickStateMenu(value)}
                theme={2}
                defaultValue={stateFilter}>
                {renderMenuListCompositionChildren()}
              </MenuListComposition>
            </div>
          </div>
          <div className={styles.AffirmationsListFilterBtnMenu}>
            <div className={styles.AffirmationsListFilterBtnMenuComponent}>
              <MultipleSelectCheckbox
                onClickValue={value => handleClickTopicMenu(value)}
                defValue={topicFilter}
              >
                {renderMultipleSelectCheckboxChildren()}
              </MultipleSelectCheckbox>
            </div>
          </div>
          <div className={styles.AffirmationsListFilterBtnMenu}>
            <div className={styles.AffirmationsListFilterBtnMenuComponent}>
              <MultipleSelectCheckbox
                isPacks={true}
                onClickValue={value => handleClickPackMenu(value)}
                defValue={packFilter}
              >
                {renderMultipleSelectCheckboxPackChildren()}
              </MultipleSelectCheckbox>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.AffirmationsListBodyContainer} style={handleTheme3Styles()}>
        {chipsUp && <div className={`${styles.AffirmationsListChipsContainer} ${styles.AffirmationsListChipsUp}`}>
          {renderChipsItems()}
        </div>}
        <div className={styles.AffirmationsListAffirmationChartContainer}>
          {handleTotalAffirmations() > 0
            ? renderAffirmationsAffirmationChart()
            : <span>{t('dashboard.IntentionsList.noData')}</span>
          }
          {/* {!limit && <div className={styles.AffirmationsListAffirmationChartPagination}>
            {handleTotalAffirmations() > 0 && <Pagination count={4} onChange={() => handlePaginationChange()} defaultPage={pagAffirmationsList} />}
          </div>} */}
        </div>
        {!chipsUp && <div className={styles.AffirmationsListChipsContainer}>
          {renderChipsItems()}
        </div>}
      </div>
    </section>
  )
}

// prop-types
AffirmationsList.propTypes = {
  /** whether chips are displayed above or below the declaration list */
  chipsUp: PropTypes.bool,
  /* whether or not its multi user */
  multiUser: PropTypes.bool,
  /* user */
  user: PropTypes.object,
  /** number of affirmations */
  limit: PropTypes.number,
  /** title if theme = 2 */
  title: PropTypes.string,
  /** theme (1, 2) */
  theme: PropTypes.number,
  /** getSensies */
  getSensies: PropTypes.func
}

export default AffirmationsList
