const CHECKBOX = {
  ALL_PACKS: 'ALL_PACKS',
  ALL_TOPICS: 'ALL_TOPICS',
  ALL_CLIENTS: 'ALL_CLIENTS',
  ALL_TEAMS: 'ALL_TEAMS',
  ALL_AFFIRMATIONS: 'ALL_AFFIRMATIONS',
  ALL_AFFIRMATIONS_BY_TOPICS: 'ALL_AFFIRMATIONS_BY_TOPICS',
  PACKS: 'PACKS',
  TOPICS: 'TOPICS',
  CLIENTS: 'CLIENTS',
  TEAMS: 'TEAMS',
  AFFIRMATIONS: 'AFFIRMATIONS',
  AFFIRMATIONS_BY_TOPICS: 'AFFIRMATIONS_BY_TOPICS',
  CLEAR_CHECKBOX: 'CLEAR_CHECKBOX'
}

export default CHECKBOX
