// react
import React from 'react'
// containers
// import Header from '../containers/Header'
// styles
import styles from './styles.module.scss'

// * page
/**
 * Team page component
 * @component
 */
const Team = () => {
  return (
    <section className={styles.TeamContainer}>
      <h1>Page under construction 🚧</h1>
      {/* <Header withBack withPeople={false} /> */}
    </section>
  )
}

export default Team
