// test
export const userTest = {
  name: 'Richard Hendricks',
  avatar: 'https://www.hardwoodandhollywood.com/pop-culture-spin/wp-content/uploads/sites/7/2015/12/richard.png'
}

export const notificationsTest = [
  {
    index: 0,
    name: 'notification',
    value: {
      url: 'https://betweenthelines477189546.files.wordpress.com/2019/05/0_xz-_chso6txphvht.png?w=1200',
      name: 'Jim Halpert',
      message: 'message 1',
      time: '2m'
    }
  },
  {
    index: 1,
    name: 'notification',
    value: {
      url: 'https://www.gstatic.com/tv/thumb/persons/494807/494807_v9_bb.jpg',
      name: 'Pedro Pascal',
      message: 'message 2',
      time: '2m'
    }
  },
  {
    index: 2,
    name: 'notification',
    value: {
      url: 'https://www.gstatic.com/tv/thumb/persons/25704/25704_v9_bb.jpg',
      name: 'Harrison Ford',
      message: 'message 3',
      time: '2m'
    }
  },
  {
    index: 3,
    name: 'notification',
    value: {
      url: 'https://www.gstatic.com/tv/thumb/persons/73414/73414_v9_bb.jpg',
      name: 'Mark Hamill',
      message: 'message 4',
      time: '2m'
    }
  }
]
